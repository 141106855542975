import './bootstrap';
import Headroom from 'headroom.js';
import AOS from 'aos';
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';
import Swiper from 'swiper';
import {Autoplay, Pagination} from 'swiper/modules';
import SmoothScroll from 'smooth-scroll';
import fslightbox from 'fslightbox';
import vhCheck from 'vh-check';

import './components/toastify';
import './components/form';
import './components/intro';

/*const header = document.getElementById('header-wrapper');
const headroom = new Headroom(header, {
    offset: 20,
});*/

// headroom.init();

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

const viewportHeight = vhCheck({
    cssVarName: 'vh-offset',
    bind: false,
});

window.onload = function () {
    AOS.init({
        offset: 0,
        // duration: 1000,
        delay: 0,
        once: true,
        anchorPlacement: 'top-center',
    });
};

AOS.init({
    easing: 'ease-out-quart',
    delay: 0,
    duration: 1000
});




// Swiper sliders
const aboutUsSwiper = new Swiper('.companies .swiper', {
    modules: [Pagination, Autoplay],
    // your swiper options

    loop: true,
    freeMode: true,
    spaceBetween: 0,
    grabCursor: true,
    autoplay: {
        delay: 1,
        disableOnInteraction: true
    },
    speed: 5000,
    freeModeMomentum: false,

    pagination: {
        clickable: true,
        dynamicBullets: true,
        el: '.swiper-pagination',
    },


    breakpoints: {



        768: {
            slidesPerView: 2,
            spaceBetween: 10,
        },

        992: {
            slidesPerView: 4.5,
            spaceBetween: 100,
        }
    },
});


// Swiper sliders
const servicesSwiper = new Swiper('.services-swiper .swiper', {
    modules: [Pagination],

    loop: true,
    pagination: {
        clickable: true,
        el: '.swiper-pagination',
        dynamicBullets: true,
    },

    breakpoints: {

        768: {
            slidesPerView: 2,
            spaceBetween: 10,
        },

        992: {
            slidesPerView: 3,
            spaceBetween: 10,
        },

        1200: {
            slidesPerView: 5.5,
            spaceBetween: 20,
        }
    },
});



/* STICKY HEADER */
// document.addEventListener('DOMContentLoaded', () => {
//     const navbar = document.querySelector('.navbar');
//     const contactInfo = document.querySelector('.contact-info');
//     let lastScrollTop = 0;
//
//     window.addEventListener('scroll', () => {
//         let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//
//         // Show/hide contact info based on scroll direction
//         if (scrollTop > lastScrollTop) {
//             // Scrolling down
//             contactInfo.classList.add('hidden');
//             navbar.style.top = "0";
//         } else {
//            contactInfo.style.background = "rgb(24, 24, 25)";
//             contactInfo.classList.remove('hidden'); // Show contact info
//             navbar.style.top = `${contactInfo.offsetHeight}px`; // Push the navbar down
//         }
//
//         // Sticky navbar logic
//         if (scrollTop > 0) {
//             navbar.classList.add('sticky-navbar');
//         } else {
//             navbar.classList.remove('sticky-navbar');
//             contactInfo.style.background = "transparent";
//         }
//
//         // Reset navbar position if scrolled to the top
//         if (scrollTop === 0) {
//             contactInfo.classList.remove('hidden'); // Show contact info
//             navbar.style.top = "0"; // Navbar stays at the top
//         }
//
//         lastScrollTop = scrollTop;
//     });
// });
//
//
//

document.addEventListener('DOMContentLoaded', () => {
    const contactInfo = document.querySelector('.contact-info');

    window.addEventListener('scroll', () => {

        if (window.scrollY > 0) {
            contactInfo.classList.add('sticky-contact-info');
        } else {
            contactInfo.classList.remove('sticky-contact-info');
        }
    });
});



document.addEventListener('DOMContentLoaded', () => {
    var lastScrollTop = 0;
    var navbar = document.getElementById("mainNav");
    var navbarNav = document.querySelector(".navbar");
    var contactInfo = document.querySelector(".contact-info");


    window.addEventListener("scroll", function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var screenWidth = window.innerWidth;

        var contactInfoHeight = contactInfo.offsetHeight;


        if (scrollTop > lastScrollTop) {
            navbarNav.style.top = "-300px";
        } else if (scrollTop > 0) {
            if (screenWidth > 992) {
                navbarNav.style.top = contactInfoHeight + "px";
                navbarNav.style.transition = "all 0.4s ease";
            } else {
                navbarNav.style.top = "0";
            }
        } else {
            navbarNav.style.top = "0";
        }

        if (scrollTop <= 0) {
            navbarNav.style.background = "transparent";
        } else {
            navbarNav.style.background = "rgb(24, 24, 25)";
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }, false);
});


/* ARROW SCROLL */
document.addEventListener('DOMContentLoaded', function() {
    var lastScrollTop = 0;
    var scrollUpButton = document.getElementById("top");

    window.addEventListener("scroll", function() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            scrollUpButton.style.opacity = "0";
        } else {
            scrollUpButton.style.opacity = scrollTop > 0 ? "1" : "0";
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    });

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    scrollUpButton.addEventListener("click", scrollToTop);
});


